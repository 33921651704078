<template lang="pug">
  div
    portal(v-if="loading" :to="`course-list-progress-bar-${item.value}`")
      v-progress-circular(indeterminate size=32 color="white")
    cmOverviewCourseRow(
      v-for="course in courses"
      :key="course.id"
      :item="course"
      @updateCounts="updateCounts"
      @updateNotes="updateNotes"
      @updateList="getCourses({})"
    )
    div.button-wrapper(v-if="pagination && pagination.next")
      v-btn(
        color="primary"
        @click="loadMore"
        :loading="loading"
      ) Load More
</template>

<script>
  import cmOverviewCourseRow from './CMOverviewCourseRow.vue'
  import errorsMixin from '@/mixins/errors.mixin'
  import { DEFAULT_PAGINATION } from '@/util/const'

  export default {
    mixins: [ errorsMixin ],
    inject: ['svc'],
    components: {
      cmOverviewCourseRow,
    },
    props: {
      item: Object
    },
    data: () => ({
      courses: [],
      loading: false,
      pagination: null,
    }),
    mounted() {
      this.getCourses({})
    },
    methods: {
      async loadMore() {
        this.pagination.loadMore();
        await this.getCourses({ more: true })
      },
      async getCourses({more = false}) {
        this.loading = true
        try {
          const { list, pagination } = await this.svc.courseListWithPagination({
            exact_date: this.item.value,
            page: this.pagination ? this.pagination.page : 1,
            size: DEFAULT_PAGINATION.SIZE,
            ...this.$route.query,
          })
          if (more) {
            this.courses = [...this.courses, ...list]
          } else {
            this.courses = list;
          }
          this.pagination = pagination
        } catch (err) {
          this.processError(err, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
        } finally {
          this.loading = false
        }
      },
      /*
        @payload:
          ID: String,
          notes: String,
       */
      updateNotes(payload) {
        const indexOfUpdatedCourse = this.courses.map(item => item.ID).indexOf(payload.ID);
        if (indexOfUpdatedCourse > -1) this.courses[indexOfUpdatedCourse].notes = payload.notes;
      },
      /*
        @payload:
          ID: Number,
          maxStudents: String,
       */
      updateCounts(payload) {
        const indexOfUpdatedCourse = this.courses.map(item => item.ID).indexOf(payload.ID);
        if (indexOfUpdatedCourse > -1) this.courses[indexOfUpdatedCourse].maxStudents = payload.maxStudents;
      },
    },
  }
</script>

<style lang="scss" scoped>

  .button-wrapper{
    margin: 8px 0;
    text-align: center;
  }


</style>